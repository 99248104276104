body,
html {
  min-height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus {
  outline: none !important;
}
